@import "variables.scss";

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #161630;
}

#root {
  height: 100%;
  width: 100%;
}

h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 3rem;
  margin: 0;
}

h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #161630;
  letter-spacing: 0;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}

ul {
  list-style: none;
}

ul,
p {
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
}

.link,
a.link {
  background: none;
  color: $a-2;
  outline: none;
  border: none;
  font-size: 1rem;
  text-align: left;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: $a-1;
  }
}

.danger {
  color: $c-danger;
}

.page {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
