$mama-bear: 680px;
$papa-bear: 1368px;
$page-padding: 1.5rem;
$f-family: "Open Sans";
// Action
$a-1: #1a43bc;
$a-2: #2154eb; // Main
$a-3: #456fee;
$a-4: #567df0;
$a-5: #ecf0fa;

// Complementary
$c-1: #5ac8d6;

// Dark
$d-1: #161630; // Text
$d-2: #999fae;
$d-3: #e3e3e6; // Borders
$d-4: #f2f5fa;
$d-5: #fcfcfc;

$c-danger: #f00024;

$mama-bear: 768px;
$papa-bear: 1368px;
